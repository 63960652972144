<template>
  <section class="visited-countries-list">
    <h2 style="color:white;">Countries I've Visited</h2>
    <div class="country-list">
      <div
        class="country-item"
        v-for="(country) in countries"
        :key="country.name"
      >
        <span class="flag">{{ country.flag }}</span>
        <p class="country-name">{{ country.name }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      countries: [
        { name: 'Bangladesh', flag: '🇧🇩' },
        { name: 'Greece', flag: '🇬🇷' },
        { name: 'Italy', flag: '🇮🇹' },
        { name: 'Vatican City', flag: '🇻🇦' },
        { name: 'Austria', flag: '🇦🇹' },
        { name: 'Slovakia', flag: '🇸🇰' },
        { name: 'Czechia', flag: '🇨🇿' },
        { name: 'Hungary', flag: '🇭🇺' },
        { name: 'Germany', flag: '🇩🇪' },
        { name: 'Qatar', flag: '🇶🇦' },
        { name: 'India', flag: '🇮🇳' },
        { name: 'Thailand', flag: '🇹🇭' },
        { name: 'Nepal', flag: '🇳🇵' },
        { name: 'Turkey', flag: '🇹🇷' },
        { name: 'Netherlands', flag: '🇳🇱' },
        { name: 'Belgium', flag: '🇧🇪' },
        { name: 'Switzerland', flag: '🇨🇭' },
        { name: 'Egypt', flag: '🇪🇬' }
      ]
    }
  }
}
</script>

<style scoped>
.visited-countries-list {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.country-list {
  max-width: 800px;
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line on smaller screens */
  justify-content: center; /* Align items to the center */
  padding: 0;
  margin: 0;
  list-style-type: none; /* Remove list-style for better appearance */
}

.country-item {
  background: #16262b;
  display: flex;
  align-items: center;
  margin: 5px; /* Space between items */
  padding: 1px 10px;
  border: 1px solid #ccc; /* Optional border to define country items */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s;
}

.country-item:hover {
  transform: scale(1.05); /* Scale up on hover */
}

.flag {
  font-size: 1em;
  margin-right: 10px; /* Space between flag and name */
}

.country-name {
        margin: 0;
  font-size: 14px; /* Adjust font size for country name */
}
</style>
