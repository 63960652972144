<template>
    <div id="app">
        <div class="xaaman-main-container-wrapper">
            <el-menu
                :default-active="activeIndex2"
                class="el-menu-demo"
                text-color="#c0c5c7"
                active-text-color="#ffd04b"
                background-color = "#17262b"
                mode="horizontal"
                position="right"
                style="border:none; z-index: 120;background: #17262B;"
            >
                <el-menu-item index="1">
                    <router-link to="/">Home</router-link>
                </el-menu-item>
                <el-menu-item v-if="this.$route.name=== 'Home'" index="3">
                    <a target="_blank" href="https://wpminers.com/blog/">
                        Blog
                    </a>
                </el-menu-item>
                <el-menu-item index="4" v-if="userLoggedIn !== ''">
                    <router-link to="/admin">Admin</router-link>
                </el-menu-item>
                <el-menu-item index="4" v-if="userLoggedIn !== ''">
                    <router-link to="/blog">Blog</router-link>
                </el-menu-item>
                <el-menu-item v-if="userLoggedIn !== ''">
                    <button @click="userLoggedOut">LogOut</button>
                </el-menu-item>
                <el-menu-item v-if="userLoggedIn === ''">
                    <router-link class="login_button" to="/login">
                        <i class="el-icon-user"></i>
                    </router-link>
                </el-menu-item>
            </el-menu>
            <div class="xaaman-main-container">
                <router-view @openChatbox="openChat"></router-view>
            </div>
        </div>
    </div>
</template>
<script>
/*eslint-disable*/
import firebase from "firebase/app";
export default {
    name: "app",
    data() {
        return {
            unseenCount: 12,
            userLoggedIn: "",
            activeIndex: "1",
            activeIndex2: "1"
        };
    },
    methods: {
        userLoggedOut() {
            firebase.auth().signOut();
            location.reload();
        },
        openChat() {
            $crisp.push(["do", "chat:open"]);
        }
    },
    mounted() {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.userLoggedIn = user.uid;
            } else {
                this.userLoggedIn = "";
            }
        });
    }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Merriweather&display=swap");
html {
    scroll-behavior: smooth;
}
p {
    line-height: 29px;
}
body {
    font-family: "Merriweather", serif;
    margin: 0px !important;
    background: #17262b;
}
.sc-chat-window.opened {
    z-index: 999999999;
}
.el-menu {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0;
}
.el-menu a {
    text-decoration: none;
    padding: 17px 0px;
}
@media screen and (max-width: 700px) {
    div#peekaboo {
        display: none;
    }
    .mentorship-container {
        &-inner-adp {
            flex-wrap: wrap;
        }
    }
}
@media screen and (min-width: 700px) {
    .project-content {
        padding-bottom: 100px;
    }
}
/* width */
::-webkit-scrollbar {
    width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 0px;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #188c91;
    border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #f5ad32;
}
.image-container {
    position: relative;
    width: 330px;
    border-top-left-radius: 49px;
    border-bottom-right-radius: 49px;
    margin: auto;
    height: 250px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.5);
}
.project-title {
    padding: 12px;
    color: #ffffff;
    background: #043639;
    margin-bottom: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
/*hover transitions*/
.single-project:hover .controller-area {
    /* border-radius: 0px 0px 6px 6px; */
    background: #303133bd;
    position: absolute;
    height: 90px;
    width: 330px;
    top: auto;
    bottom: 0;
    z-index: 10;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-font-smoothing: antialiased;
}
.single-project:hover .project-controller {
    display: flex !important;
}

/*hover trans stop*/
.project-controller {
    display: none;
    justify-content: center;
    position: absolute;
    top: 40%;
    left: 29%;
}
.el-dialog__body {
    height: 600px;
}
.el-dialog {
    width: 83%;
}
.project-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin: 32px 0px 64px 0px;
}
.single-project {
    max-width: 335px;
    margin: 0px 24px 24px 0px;
}
.el-carousel__item:nth-child(2n) {
    background-color: #d3dce6;
}
/*universal posts*/
.post-image {
    border-radius: 12px;
    max-width: 100%;
}
ul.post-lists li {
    list-style: upper-roman;
    margin-bottom: 32px;
    margin-top: 32px;
}
.post-fruitnote {
    margin-top: 88px;
    max-width: 1000px;
    border-radius: 66px;
}
.post-fruitnote p {
    background: #131F22;
    padding: 13px;

    border-radius: 14px;
}
.post-margin-top {
    margin-top: 32px;
}
.post-margin-bottom {
    margin-bottom: 32px;
}
.post-time {
    font-family: monospace;
    margin: -8px 2px;
    font-size: 14px;
}
.post-padding-all {
    padding: 32px;
}


</style>
